// extracted by mini-css-extract-plugin
export var our_journey = "styles-module--our_journey--4f0b0";
export var our_journey__content = "styles-module--our_journey__content--b828e";
export var our_journey__content__first_section = "styles-module--our_journey__content__first_section--2d1d6";
export var our_journey__content__first_section__content = "styles-module--our_journey__content__first_section__content--17e71";
export var our_journey__content__first_section__content_link = "styles-module--our_journey__content__first_section__content_link--17275";
export var our_journey__content__first_section__content_link_arrow = "styles-module--our_journey__content__first_section__content_link_arrow--d09e2";
export var our_journey__content__first_section__content_links = "styles-module--our_journey__content__first_section__content_links--f4af3";
export var our_journey__content__second_section = "styles-module--our_journey__content__second_section--e9a9d";
export var our_journey__content__second_section__container = "styles-module--our_journey__content__second_section__container--cf0c3";
export var our_journey__content__second_section__container__image = "styles-module--our_journey__content__second_section__container__image--dac70";
export var our_journey__content__second_section__container__links = "styles-module--our_journey__content__second_section__container__links--c71bd";
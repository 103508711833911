import { Helmet } from "react-helmet";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import AboutLink from "./components/AboutLink";
import BannerSection from "./components/BannerSection";
import ExploreMore from "./components/ExploreMore";
import ScrollMenu from "./components/ScrollMenu";
import SideMenu from "./components/SideMenu";
import ArrowIcon from "../../components/icons/svgs/arrow.svg";
import SEO from "../../components/seo";

import reportPDF from "./ourJourney/2023 Impact Report_Ana Luisa.pdf";

// Images
import firstBannerImgD from "../../images/about_images/Our Journey/Desktop/OurJourney_hero_desktop.jpg";
import firstBannerImgM from "../../images/about_images/Our Journey/Mobile/OurJourney_hero_mobile.jpg";
import secondBannerImgD from "../../images/about_images/Our Journey/Desktop/OurJourney_tile2_desktop.jpg";
import secondBannerImgM from "../../images/about_images/Our Journey/Mobile/OurJourney_tile2_mobile.jpg";
import infoD from "../../images/about_images/Our Journey/Desktop/OurJourney_infographic_desktop.jpg";
import infoM from "../../images/about_images/Our Journey/Mobile/OurJourney_infographic_mobile.jpg";

import * as Styles from "./ourJourney/styles.module.css";

const menuItems = [
  {
    title: "Our Story",
    url: "/pages/our-journey",
    isActive: true,
  },
  {
    title: "Our Jewelry",
    url: "/pages/our-jewelry",
    isActive: false,
    subMenuItems: [
      {
        title: "Quality",
        url: "",
      },
      {
        title: "Materials & Care",
        url: "",
      },
      {
        title: "Packaging",
        url: "",
      },
    ],
  },
  {
    title: "Our People",
    url: "/pages/our-people",
    isActive: false,
    subMenuItems: [
      {
        title: "Factories",
        url: "",
      },
      {
        title: "Audits",
        url: "",
      },
      {
        title: "Social standards",
        url: "",
      },
    ],
  },
  {
    title: "Our Planet",
    url: "/pages/our-planet",
    isActive: false,
    subMenuItems: [
      {
        title: "Impact assessment",
        url: "",
      },
      {
        title: "Carbon neutrality",
        url: "",
      },
      {
        title: "Water neutrality",
        url: "",
      },
      {
        title: "Circularity",
        url: "",
      },
    ],
  },
];

function OurJourney({ path }) {
  const knowBetterHeaderClass = useResponsiveWithHydrationFix("al_h3", "al_h2");
  const infographicSrc = useResponsiveWithHydrationFix(infoM, infoD);

  return (
    <div className={Styles.our_journey}>
      <SEO
        title="Our Journey"
        description="Discover Ana Luisa's sustainable journey towards becoming a more eco-conscious jewelry brand. Explore our commitment to ethical sourcing, responsible production practices, and eco-friendly materials. Join us in our mission to create beautiful, sustainable jewelry that makes a positive impact on both style and the planet. Shop now and embrace conscious fashion at analuisa.com."
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <ScrollMenu active="journey" />
      <SideMenu menuItems={menuItems} />
      <div className={Styles.our_journey__content}>
        <BannerSection
          className={`${Styles.our_journey__content__first_section}`}
          classNameContent={Styles.our_journey__content__first_section__content}
          imageSrc={{ desktop: firstBannerImgD, mobile: firstBannerImgM }}
          imageAlt="Ana Luisa Jewelry Header"
        >
          <h1 className="al_h3">
            At Ana Luisa, we hold our jewelry to a higher standard. Because the best investment you
            can make is in yourself – and our planet.
          </h1>
          <p className="al_p">
            Since our founding in 2018, we’ve worked to elevate the everyday of people around the
            world with Earth-friendly jewelry that lasts. We continue to challenge the jewelry
            industry by bridging the gap between quality, accessibility, and sustainability. We
            craft our jewelry to stand the test of time, making sure you know how and where your
            pieces were made, and guarantee your purchase stays within your budget. Our designs are
            timeless. Our quality is unmatched. And our commitment to the environment never wavers.
          </p>
          <div className={Styles.our_journey__content__first_section__content_links}>
            <a
              className={`${Styles.our_journey__content__first_section__content_link} al_p--bold`}
              href={reportPDF}
              target="_blank"
              rel="noreferrer"
            >
              READ IMPACT REPORT
              <ArrowIcon
                className={Styles.our_journey__content__first_section__content_link_arrow}
              />
            </a>
            <AboutLink
              to="/collections/bestsellers"
              className={`${Styles.our_journey__content__first_section__content_link} al_p--bold al_p--cap `}
            >
              Shop Best Sellers
            </AboutLink>
          </div>
        </BannerSection>
        <BannerSection
          className={Styles.our_journey__content__second_section}
          imageSrc={{ desktop: secondBannerImgD, mobile: secondBannerImgM }}
          imageAlt="Ana Luisa Jewelry Header"
        >
          <h2 className={knowBetterHeaderClass}>Know Better, Do Better</h2>
          <p className="al_p">
            We’re committed to sustainable practices backed by trusted partners, experts, and
            scientists. We use recycled materials wherever possible, avoid unnecessary mining and
            excessive markups, and ensure 100% carbon neutrality through certified projects. Jewelry
            feels even more beautiful knowing it was made with the planet in mind.
          </p>
          <div className={Styles.our_journey__content__second_section__container}>
            <div className={Styles.our_journey__content__second_section__container__links}>
              <AboutLink to="/pages/our-jewelry">Discover our design philosophy</AboutLink>
              <AboutLink to="/pages/our-people">Discover our craftsmanship</AboutLink>
              <AboutLink to="/pages/our-planet">
                Discover our commitment towards the planet
              </AboutLink>
            </div>
            <img
              className={Styles.our_journey__content__second_section__container__image}
              src={infographicSrc}
              alt="People, product, and planet infographic"
            />
          </div>
        </BannerSection>
        <ExploreMore current="journey" />
      </div>
    </div>
  );
}

export default OurJourney;
